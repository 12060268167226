body .react-datepicker {
  font: var(--fora-main-font);
}

.field-calendar {
  &.react-datepicker {
    border: 1px solid rgba(20, 20, 20, 0.1);
    margin-top: 4px;
    padding: 16px 4px;
    position: absolute;
    z-index: 5;
  }
  .react-datepicker__navigation--previous {
    top: 23px;
    left: 9px;
  }
  .react-datepicker__navigation--next {
    top: 23px;
    right: 9px;
  }
  .react-datepicker__day,
  .react-datepicker__day-name {
    margin: 8px 7px;
  }
  .react-datepicker__day--disabled {
    opacity: 0.5;

    &:hover {
      background: transparent !important;
    }
  }
}
@media screen and (max-width: 470px) {
  .field-calendar {
    &.react-datepicker {
      width: 100%;
    }

    .react-datepicker__day,
    .react-datepicker__day-name {
      margin-right: 5px !important;
      margin-left: 5px !important;
    }
    .react-datepicker__day {
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }
}
.filter-calendar {
  &.react-datepicker {
    border: 0 none;
    font-family: inherit;
  }
  .react-datepicker__navigation--previous {
    left: -10px;
    top: 6px;
  }
  .react-datepicker__navigation--next {
    right: -10px;
    top: 6px;
  }
  .react-datepicker__day,
  .react-datepicker__day-name {
    margin: 3px 7px;
  }
}
.desktop-calendar--new-experience-wrapper {
  .filter-calendar {
    &.react-datepicker {
      .react-datepicker__navigation--next,
      .react-datepicker__navigation--previous {
        top: 175px;
      }
      .react-datepicker__navigation-icon {
        &:before {
          border-color: var(--fora-text-color-main);
        }
      }
    }
  }
}
.filter-calendar,
.field-calendar {
  .react-datepicker__day--outside-month {
    visibility: hidden;
  }

  .react-datepicker__month-container {
    width: 100%;
  }
  .react-datepicker__current-month {
    font-size: 15px;
    line-height: 24px;
  }

  .react-datepicker__header {
    background: transparent;
    border-bottom: 0 none;
  }
  .react-datepicker__day,
  .react-datepicker__day-name {
    color: #141414;
  }
  .react-datepicker__day-name {
    margin-top: 24px;
    font-size: 15px;
    line-height: 24px;
    color: var(--fora-color-secondary);
  }
  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow,
  .react-datepicker__navigation-icon::before {
    border-color: #6b7280;
    border-width: 1px 1px 0 0;
  }
  .react-datepicker__month,
  .react-datepicker__day-names {
    margin: 0;
    transform: translateX(0); /*don't remove this*/
  }
  .react-datepicker__month {
    overflow: hidden;
  }
  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover,
  .react-datepicker__year-text:hover {
    background: #ccf0e4;
    border-radius: 50%;
  }

  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    border-radius: 50%;
    background: transparent;
    color: #000;
  }

  .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
  .react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
  .react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
  .react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
    background: rgb(33, 185, 138, 0.3);
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    background: transparent;
    border-radius: 50%;
    color: inherit;
  }
  .react-datepicker__day--today:not(.react-datepicker__day--range-end) {
    border-radius: 50% !important;
    outline: 2px solid rgb(33, 185, 138);
    width: 22px !important;
    height: 22px !important;
    line-height: 22px !important;

    &:before {
      top: -1px !important;
      bottom: -1px !important;
    }

    &:hover {
      background: #ccf0e4;
      color: #141414;
    }
  }
  .react-datepicker__day--keyboard-selected:hover,
  .react-datepicker__month-text--keyboard-selected:hover,
  .react-datepicker__quarter-text--keyboard-selected:hover,
  .react-datepicker__year-text--keyboard-selected:hover {
    background: rgb(33, 185, 138);
    border-radius: 50%;
  }
  .react-datepicker__day--selected:hover,
  .react-datepicker__day--in-selecting-range:hover,
  .react-datepicker__day--in-range:hover,
  .react-datepicker__month-text--selected:hover,
  .react-datepicker__month-text--in-selecting-range:hover,
  .react-datepicker__month-text--in-range:hover,
  .react-datepicker__quarter-text--selected:hover,
  .react-datepicker__quarter-text--in-selecting-range:hover,
  .react-datepicker__quarter-text--in-range:hover,
  .react-datepicker__year-text--selected:hover,
  .react-datepicker__year-text--in-selecting-range:hover,
  .react-datepicker__year-text--in-range:hover {
    background: rgb(33, 185, 138);
    border-radius: 50%;
    color: #fff;
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--range-start,
  .react-datepicker__day--range-end {
    background: rgb(33 185 138) !important;
    border-radius: 50% !important;
    color: #fff;
  }
  .react-datepicker__day--range-end {
    position: relative;
    &:after {
      background: #fff;
      content: "";
      display: inline-block;
      position: absolute;
      left: 50%;
      width: 50%;
      height: 100%;
      z-index: -1;
    }
  }

  .react-datepicker__day--in-range:not(.react-datepicker__day--range-end) {
    position: relative;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      width: 60px; // used with non-transparent background
      //background: rgb(33, 185, 138, 0.3);
      background: #ccf0e4;
      z-index: -1;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
  }
  //.react-datepicker__day--in-range:not(.react-datepicker__day--range-start),
  .react-datepicker__day--in-range:not(.react-datepicker__day--range-end) {
    background: #ccf0e4;
    border-radius: 0;
  }
}
/*rgb(33 185 138 / var(--tw-text-opacity)) rgba(86, 210, 172, 0.1)*/
.desktop-calendar-container {
  width: 712px;
  .filter-calendar {
    .react-datepicker__month-container {
      width: 312px;
    }
    .react-datepicker__month-container + .react-datepicker__month-container {
      margin-left: 47px;
    }
    .react-datepicker__day,
    .react-datepicker__day-name {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}

//@media screen and (min-width: 425px) {
.filter-calendar,
.field-calendar {
  .react-datepicker__day,
  .react-datepicker__day-name {
    margin-left: 11px;
    margin-right: 12px;
    line-height: 24px;
    font-size: 15px;
    width: 24px;
  }
}
//}

.react-datepicker__header--custom-content {
  .react-datepicker__navigation {
    position: relative;
    top: 0;
  }
  .react-datepicker__navigation-icon::before {
    top: 9px;
  }
}
.react-datepicker__day--fixed-in-selecting-range {
  background: none !important;
}

.react-datepicker__day--disabled {
  opacity: 0.5 !important;

  &:hover {
    background: transparent !important;
  }
}

.mobile-calendar--new-experience-wrapper,
.desktop-calendar--new-experience-wrapper {
  .react-datepicker__day-name {
    margin-bottom: 0 !important;
  }
  .react-datepicker__day--selected:hover,
  .react-datepicker__day--in-selecting-range:hover,
  .react-datepicker__day--in-range:hover,
  .react-datepicker__month-text--selected:hover,
  .react-datepicker__month-text--in-selecting-range:hover,
  .react-datepicker__month-text--in-range:hover,
  .react-datepicker__quarter-text--selected:hover,
  .react-datepicker__quarter-text--in-selecting-range:hover,
  .react-datepicker__quarter-text--in-range:hover,
  .react-datepicker__year-text--selected:hover,
  .react-datepicker__year-text--in-selecting-range:hover,
  .react-datepicker__year-text--in-range:hover {
    background: var(--fora-primary-color);
    color: var(--fora-text-color-main);
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--range-start,
  .react-datepicker__day--range-end {
    background: var(--fora-primary-color) !important;
    font-weight: bold;
    color: var(--fora-text-color-main);
  }
  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover,
  .react-datepicker__year-text:hover {
    background: var(--fora-primary-color);
  }
  .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
  .react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
  .react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
  .react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
    background: var(--fora-main-holiday-color);
  }
  .react-datepicker__day--today:not(.react-datepicker__day--range-start),
  .react-datepicker__day--today:not(.react-datepicker__day--range-end) {
    outline: 2px solid var(--fora-primary-color);
    width: 36px !important;
    height: 36px !important;
    line-height: 36px !important;

    &:before {
      top: -1px !important;
      bottom: -1px !important;
    }

    &:hover {
      background: var(--fora-primary-color);
      color: #141414;
    }
  }
  .filter-calendar,
  .field-calendar {
    .react-datepicker__day,
    .react-datepicker__day-name {
      margin: 8px 5px;
      line-height: 40px;
      font-size: 13px;
      width: 40px;
    }
  }
  .filter-calendar
    .react-datepicker__day--in-range:not(.react-datepicker__day--range-end),
  .field-calendar
    .react-datepicker__day--in-range:not(.react-datepicker__day--range-end) {
    background: var(--fora-main-holiday-color);
    border-radius: 50%;
  }
  .react-datepicker__day--in-range:not(.react-datepicker__day--range-end) {
    position: relative;
    &:before {
      content: none;
    }
  }

  .Cal__Day__range.Cal__Day__selected {
    &.Cal__Day__start,
    &.Cal__Day__end {
      &:after {
        content: none;
      }

      .Cal__Day__selection {
        background-color: var(--fora-primary-color) !important;
        color: var(--fora-text-color-main) !important;
      }
    }
    &.Cal__Day__betweenRange .Cal__Day__selection {
      border-radius: 50%;
      width: 52px; /*Attention: it dependents on InfiniteCalendar rowHeight value*/
      //border: 6px solid #fff;  TODO to design request
      margin: auto;
      top: 2px;
    }
  }
  .Cal__Day__root.Cal__Day__selected .Cal__Day__selection .Cal__Day__day {
    position: relative;
    top: -2px;
    font-weight: normal;
    //font-size: 13px;
  }
  .Cal__Day__root.Cal__Day__selected.Cal__Day__betweenRange
    .Cal__Day__selection
    .Cal__Day__day {
    top: 0;
  }
  .Cal__Day__root.Cal__Day__selected {
    &.Cal__Day__start,
    &.Cal__Day__end {
      .Cal__Day__selection {
        //border: 6px solid #fff !important; TODO to design request
        border: 0 none;
        .Cal__Day__day {
          top: -2px;
          font-weight: bold;
        }
      }
    }
  }

  .Cal__Day__root {
    //font-size: 13px;
  }
  .Cal__Month__row {
  }
  .Cal__Day__month {
    font-weight: bold;
  }
  .Cal__Day__selection {
    .Cal__Day__month {
      display: none;
    }
  }
  .Cal__Month__rows {
    .Cal__Month__row:first-child {
      li:first-child {
        .Cal__Day__month {
          display: block;
        }
      }
    }
  }
  .Cal__Month__rows {
    background: #fff;
  }
}

.mobile-calendar--new-experience-wrapper {
  .react-datepicker__current-month {
    text-align: left;
    margin-top: 16px;
  }
  .react-datepicker__navigation {
    display: none;
  }
}
.desktop-calendar--new-experience-wrapper {
  .filter-calendar,
  .field-calendar {
    .react-datepicker__day-name {
      font-family: AktivGrotesk;
      font-weight: bold;
    }
  }
  .react-datepicker__header {
    padding: 0;
  }
}
button[data-id="calendarClearButton"]:disabled {
  color: var(--fora-disabled-secondary-color);
  opacity: 1;
}
