/* AktivGrotesk */

@font-face {
  font-family: AktivGrotesk;
  src: url("fonts/AktivGrotesk_Hair.ttf") format("truetype");
  font-weight: 100;
}

@font-face {
  font-family: AktivGrotesk;
  src: url("fonts/AktivGrotesk_HairIt.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: AktivGrotesk;
  src: url("fonts/AktivGrotesk_Th.ttf") format("truetype");
  font-weight: 200;
}

@font-face {
  font-family: AktivGrotesk;
  src: url("fonts/AktivGrotesk_ThIt.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: AktivGrotesk;
  src: url("fonts/AktivGrotesk_Lt.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: AktivGrotesk;
  src: url("fonts/AktivGrotesk_LtIt.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: AktivGrotesk;
  src: url("fonts/AktivGrotesk_Rg.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: AktivGrotesk;
  src: url("fonts/AktivGrotesk_It.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: AktivGrotesk;
  src: url("fonts/AktivGrotesk_Md.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: AktivGrotesk;
  src: url("fonts/AktivGrotesk_MdIt.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: AktivGrotesk;
  src: url("fonts/AktivGrotesk_SBd.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: AktivGrotesk;
  src: url("fonts/AktivGrotesk_SBdIt.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: AktivGrotesk;
  src: url("fonts/AktivGrotesk_Bd.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: AktivGrotesk;
  src: url("fonts/AktivGrotesk_BdIt.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: AktivGrotesk;
  src: url("fonts/AktivGrotesk_XBd.ttf") format("truetype");
  font-weight: 800;
}

@font-face {
  font-family: AktivGrotesk;
  src: url("fonts/AktivGrotesk_XBdIt.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: AktivGrotesk;
  src: url("fonts/AktivGrotesk_Blk.ttf") format("truetype");
  font-weight: 900;
}

@font-face {
  font-family: AktivGrotesk;
  src: url("fonts/AktivGrotesk_BlkIt.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}

/* Other */

@font-face {
  font-family: AktivGroteskCd;
  src: url("fonts/AktivGroteskCd_Md.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: KeplerStd;
  src: url("fonts/KeplerStd-Regular.otf") format("opentype");
  font-weight: normal;
}

@font-face {
  font-family: Blanco;
  src: url("fonts/Blanco-Regular.otf") format("opentype");
  font-weight: normal;
}

@font-face {
  font-family: Blanco;
  src: url("fonts/Blanco-Bold.otf") format("opentype");
  font-weight: 700;
}

@font-face {
  font-family: Blanco;
  src: url("fonts/Blanco-BoldItalic.otf") format("opentype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: Blanco;
  src: url("fonts/Blanco-Italic.otf") format("opentype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Chiswick Sans Text";
  src: url("fonts/ChiswickSansText-Regular.otf") format("opentype");
  font-weight: normal;
}

@font-face {
  font-family: "Chiswick Sans Text";
  src: url("fonts/ChiswickSansText-Semibold.otf") format("opentype");
  font-weight: 600;
}