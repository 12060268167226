.rbc-time-view,
.rbc-time-gutter .rbc-timeslot-group {
  border: transparent;
}

.rbc-header {
  border-left: none !important;
  background: #fff;
  font-weight: 500;
}

.rbc-time-header,
.rbc-time-header-content {
  border: none !important;
}
/* */
.rbc-allday-cell::before,
.rbc-allday-cell::after {
  content: "";
  position: absolute;
  width: 1px;
  top: 0;
  bottom: 0;
  background: #ddd;
}
.rbc-allday-cell::before {
  left: 0;
}
.rbc-allday-cell::after {
  right: 0;
}
.rbc-allday-cell {
  position: relative;
}
.rbc-time-content {
  border-right: 1px solid #ddd;
  overflow-y: visible;
  border-top: 0;
}
.rbc-allday-cell {
  border-bottom: 1px solid #ddd;
}
.rbc-time-slot {
  border: transparent !important;
}
.rbc-time-view .rbc-row {
  min-height: 0;
}
.rbc-row.rbc-time-header-cell {
  padding-top: 10px;
  min-height: 80px;
}

.rbc-label {
  padding: 0 10px 0 0;
}

.rbc-timeslot-group {
  min-height: 48px;
}

.rbc-time-header {
  @apply sticky top-[133px] desktop:top-[137px] z-[9];
}
.rbc-time-header-cell-single-day {
  display: block;
  border-bottom: 1px solid #ddd;
}
.rbc-current-time-indicator {
  background-color: #e60000;
}

.rbc-current-time-indicator::before {
  content: "";
  position: absolute;
  width: 7px;
  height: 7px;
  left: -4px;
  top: -3px;
  background: #e60000;
  border-radius: 50%;
}
.rbc-day-bg {
  background-color: #fff;
}
.rbc-row-bg {
  margin-left: 1px;
}
.calendar-widget .rbc-time-content > * + * > * {
  border-left: 0;
}
.calendar-widget .rbc-time-content {
  border-right-color: transparent;
}

.calendar-widget .rbc-time-header {
  top: -28px;
}
.rbc-time-header-cell {
  background: #fff;
}
.calendar-widget .rbc-time-header-gutter {
  position: relative;
}
.calendar-widget .rbc-time-header-gutter:after {
  content: "";
  position: absolute;
  top: 27px;
  left: 0;
  right: 0;
  height: 30px;
  background: #fff;
}
.rbc-today {
  background: #fff;
}

.calendar-widget .rbc-today {
  border-bottom-color: transparent;
}
